import React, { useState, useEffect } from "react";
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import "./App.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const width = 400;
const height = 600;

const Page = React.forwardRef(({ pageNumber }, ref) => {
  return (
    <div ref={ref} className="page">
      <ReactPdfPage pageNumber={pageNumber} width={width} />
    </div>
  );
});

const Test = () => {
  const [numPages, setNumPages] = useState(null);
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [pdfData, setPdfData] = useState(null);

  useEffect(() => {
    setNumPages(null);
    setPdfLoaded(false);
    setPdfData(null);

    const pdfUrl = 'https://firebasestorage.googleapis.com/v0/b/verbal-autism-testing.appspot.com/o/VAD.pdf?alt=media&token=f3877328-98ae-451a-b915-9a1caa365e99';

    fetch(pdfUrl)
      .then((response) => response.arrayBuffer())
      .then((data) => {
        setPdfData(new Uint8Array(data));
      })
      .catch((error) => console.error("Error fetching PDF:", error));

    return () => {
      // Clear resources when the component is unmounted
      setNumPages(null);
      setPdfLoaded(false);
      setPdfData(null);
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPdfLoaded(true);
  };

  const renderPdfPages = () => {
    const pages = [];
    for (let pageNumber = 1; pageNumber <= numPages; pageNumber += 2) {
      pages.push(
        <div key={pageNumber} className="double-page">
          <Page pageNumber={pageNumber} />
          {pageNumber + 1 <= numPages && <Page pageNumber={pageNumber + 1} />}
        </div>
      );
    }
    return pages;
  };

  return (
    <div className="pdf-container">
      {pdfData && (
        <Document file={{ data: pdfData }} onLoadSuccess={onDocumentLoadSuccess}>
          {pdfLoaded && (
            <HTMLFlipBook width={width} height={height} size="fixed">
              {renderPdfPages()}
            </HTMLFlipBook>
          )}
        </Document>
      )}
    </div>
  );
};

export default Test;
